@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700|Roboto:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap");

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 1024px;
    -webkit-print-color-adjust: exact;
  }
}

body,
html {
  scroll-behavior: smooth;
}

body .ant-app {
  height: 100vh;
}

body ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #b8bdba;
}
body ::-webkit-scrollbar-track {
  background: transparent;
}
.tester-board::-webkit-scrollbar {
  height: 12px !important;
}
::placeholder {
  font-weight: normal;
}
.ui.container {
  min-width: 90% !important;
}
button.ui.button {
  border-radius: 2px !important;
  min-width: 90px;
  transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s, border-color ease-in-out 0.2s !important;
}
button.ui.button.circular {
  border-radius: 10em !important;
  min-width: auto;
}
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label {
  opacity: 1 !important;
}
.ui.input input,
.ui.form .field.field .ui.input input:-webkit-autofill,
.ui.form textarea,
.ui.form textarea:-webkit-autofill {
  border: 1px solid transparent !important;
  background-color: #f2f2f2 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  font-family: "Roboto", sans-serif !important;
}
.ui.input input:focus,
.ui.form textarea:focus {
  border: 1px solid #383838 !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5) !important;
}
input[readonly],
textarea[readonly],
.pointer-none {
  pointer-events: none !important;
}
.pointer-all {
  pointer-events: all !important;
}
.ui.form .field.error .input input,
.ui.form .field.error .ui.dropdown,
.ui.form .field.error textarea,
.ui.input .ant-input.ant-error {
  border: 1px solid #f64c66 !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 8px 0 rgba(246, 76, 102, 0.5) !important;
}
.ui.form .field.error label {
  color: #f64c66 !important;
}
.position-relative .ui.basic.red.label {
  position: absolute;
  /* left: 10rem; */
  top: -1rem;
}
.ui.container.position-relative .ui.basic.red.label {
  top: -2.5rem;
}
.ui.basic.red.label {
  color: #f64c66 !important;
  border-color: #f64c66 !important;
}
.ui.black.button {
  background-color: #ffffff !important;
}
.ui.basic.black.button:hover,
.ui.basic.black.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #383838 inset !important;
  color: #ffffff !important;
  background-color: #383838 !important;
}
.ui.primary.button {
  background-color: #2cbfca;
}
.ui.primary.button:hover,
.ui.primary.button:focus,
.ui.primary.button:active {
  background-color: #17828a;
}
.ui.basic.primary.button,
.ui.basic.primary.buttons .button,
.ui.inverted.primary.button {
  box-shadow: 0 0 0 1px #2cbfca inset !important;
  color: #2cbfca !important;
  background-color: white !important;
}
.ui.inverted.primary.button:hover {
  color: #fff !important;
  background-color: #2cbfca !important;
}
.ui.basic.primary.button:hover,
.ui.basic.primary.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #17828a inset !important;
  color: #17828a !important;
}
.ui.pink.button {
  background-color: #f64c66;
}
.ui.lightgreen.inverted {
  box-shadow: 0 0 0 1px #32c18e inset !important;
  color: #32c18e;
}
.ui.lightgreen.inverted:hover,
.ui.lightgreen.inverted:focus {
  background: #32c18e !important;
  box-shadow: 0 0 0 1px #32c18e inset !important;
  color: #fff;
}
.ui.lightgreen.inverted:hover img.text-white,
.ui.error.inverted.import-btn:hover img.text-white {
  filter: brightness(0) invert(1);
}
.ui.error.inverted.import-btn img {
  filter: sepia() saturate(35) hue-rotate(312deg);
}
.ui.basic.buttons button.ui.active.button {
  background-color: #2196f3 !important;
  color: white !important;
}
.ui.button.btn-link {
  background: transparent;
  padding: 0;
  margin: auto;
}
.ui.inverted.error.button.primary,
.ui.error.inverted.import-btn {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fa3666 inset !important;
  color: #fa3666 !important;
}
.ui.inverted.primary.error.button:hover,
.ui.error.inverted.import-btn:hover {
  background-color: #fa3666 !important;
  color: white !important;
}
h2,
h3,
h4,
.roboto,
.ui.button {
  font-family: "Roboto", sans-serif !important;
}

.ui.header .sub.header {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.lato {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
.helvetica {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}
.m-auto {
  margin: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1px {
  margin: 1px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-05 {
  margin-left: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
.ml-1px {
  margin-left: 1px;
}
.ml-2px {
  margin-left: 2px;
}
.ml-3px {
  margin-left: 3px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mr-2 {
  margin-right: 1rem !important;
}
.mr-3 {
  margin-right: 1.5rem !important;
}
.mr-4 {
  margin-right: 2rem !important;
}
.mr-1px {
  margin-right: 1px;
}
.mr-10px {
  margin-right: 10px !important;
}
.mb-1px {
  margin-bottom: 1px;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.mb-3rem {
  margin-bottom: 3rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem !important;
}
.mt-3 {
  margin-top: 1.5rem !important;
}
.mt-4 {
  margin-top: 2rem !important;
}
.mt-5 {
  margin-top: 2.5rem !important;
}
.mt-10 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 3rem !important;
}
.mt-1px {
  margin-top: 1px;
}
.mt-2px {
  margin-top: 2px;
}
.mt-3px {
  margin-top: 3px;
}
.mt-15px {
  margin-top: 15px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.p-2px {
  padding: 2px;
}
.p-5px {
  padding: 5px;
}
.p-10px {
  padding: 10px !important;
}
.p-0,
.ant-collapse-item.no-padd .ant-collapse-header,
.ant-collapse-item.no-padd .ant-collapse-content-box {
  padding: 0 !important;
}
.p-1 {
  padding: 0.5rem !important;
}
.p-2 {
  padding: 1rem !important;
}
.p-3 {
  padding: 1.5rem !important;
}
.p-4 {
  padding: 2rem !important;
}
.p-5 {
  padding: 2.5rem !important;
}
.p-5rem {
  padding: 5rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1,
.pt-1.ui.label {
  padding-top: 0.5rem !important;
}
.pt-2 {
  padding-top: 1rem !important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pt-4 {
  padding-top: 2rem !important;
}
.pt-5 {
  padding-top: 2.5rem !important;
}
.pt-4_2 {
  padding-top: 4.2rem !important;
}
.pt-5rem {
  padding-top: 5rem !important;
}
.pt-45 {
  padding-top: 4.5rem !important;
}
.pt-7px {
  padding-top: 7px;
}
.pt-10px {
  padding-top: 10px;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 0.5rem !important;
}
.pr-2,
.pr-2.ui.label {
  padding-right: 1rem !important;
}
.pr-3 {
  padding-right: 1.5rem !important;
}
.pr-4 {
  padding-right: 2rem !important;
}
.pr-5rem {
  padding-right: 5rem !important;
}
.pr-3px {
  padding-right: 3px;
}
.pr-5px {
  padding-right: 5px !important;
}
.pr-38px {
  padding-right: 38px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-2px {
  padding-bottom: 2px !important;
}
.pb-5px {
  padding-bottom: 5px !important;
}
.pb-7px {
  padding-bottom: 7px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-1,
.pb-1.ui.label {
  padding-bottom: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 1rem !important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 2rem !important;
}
.pb-5 {
  padding-bottom: 2.5rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 0.5rem !important;
}
.pl-2,
.pl-2.ui.label {
  padding-left: 1rem !important;
}
.pl-3 {
  padding-left: 1.5rem !important;
}
.pl-4 {
  padding-left: 2rem !important;
}
.pl-5rem {
  padding-left: 5rem !important;
}
.pl-3px {
  padding-left: 3px;
}
.pl-38px {
  padding-left: 38px !important;
}
.pl-7px {
  padding-left: 7px !important;
}
.pl-10px {
  padding-left: 10px;
}
.bg-white {
  background: #ffffff !important;
}
.d-inline {
  display: inline !important;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.d-none,
.swiper-button-disabled,
.h-bg-gray-2 .placeholder,
.h-bg-green-3 .placeholder {
  display: none !important;
}
.d-grid {
  display: grid !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.grid-template-auto {
  grid-template-columns: auto auto auto;
}
.gid-gap-20px {
  grid-gap: 20px;
}
.gap-1 {
  gap: 0.5rem !important;
}
.gap-2 {
  gap: 1rem !important;
}
.gap-3 {
  gap: 1.5rem !important;
}
.gap-5px {
  gap: 5px !important;
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}
.flex-75 {
  flex: 0.75 !important;
}
.flex-50 {
  flex: 0.5 !important;
}
.flex-25 {
  flex: 0.25 !important;
}
.flex-none {
  flex: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column,
.flex-direction-col {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row;
}
.flex-grow-init {
  flex-grow: initial !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-static {
  position: static !important;
}
.position-sticky {
  position: sticky !important;
  top: 0;
  z-index: 100;
}
.zi-99 {
  z-index: 99 !important;
}
.left-0 {
  left: 0 !important;
}
.left-1rem {
  left: 1rem !important;
}
.right-0 {
  right: 0 !important;
}
.right-1 {
  right: 1rem !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-1 {
  bottom: 1rem !important;
}
.top-0 {
  top: 0 !important;
}
.top-1 {
  top: 1rem !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
  justify-content: center !important;
}
.text-right {
  text-align: right !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.hover-underline:hover {
  text-decoration: underline !important;
}
.font-weight-bold,
.font-bold,
.react-calendar__navigation__label {
  font-weight: bold !important;
}
.fwn,
.font-weight-normal {
  font-weight: normal !important;
}
.font-weight-italic,
.font-italic {
  font-style: italic !important;
}
.font-normal {
  font-style: normal;
}
.font-400 {
  font-weight: 400 !important;
}
.font-500,
.font-weight-500,
.font-500 .ant-input {
  font-weight: 500 !important;
}
.font-600 {
  font-weight: 600 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.pointer {
  cursor: pointer !important;
}
.grab {
  cursor: grab !important;
}
.grabbing {
  cursor: grabbing !important;
}
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
i.help.circle.icon {
  font-size: 15px;
  color: #c0c0c0;
  cursor: pointer;
  margin: -3px 5px 0;
}
.ui.page.modals.dimmer.transition.visible.active {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: "Roboto", sans-serif !important;
}
.word-break-all {
  word-break: break-all;
}
.ui.inverted.popup {
  padding: 0.5rem 1rem;
}
i.disabled.icon {
  cursor: no-drop !important;
}
.border-none,
.border-0,
.border-none .ui.selection.dropdown {
  border: none !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.border-top-none {
  border-top: none !important;
}
.bl-5px-primary {
  border-left: 5px solid var(--primary-color) !important;
}
.border-left-none,
.ui.modal .ui.table .border-left-none {
  border-left: none !important;
}
.border-right-none {
  border-right: none !important;
}
.shadow-none {
  box-shadow: none !important;
}
.shadow-primary-1 {
  box-shadow: 0px 5px 30px rgba(42, 119, 244, 0.5);
}
.shadow-gray {
  box-shadow: 0px 1.96403px 3.27338px rgba(0, 0, 0, 0.15);
}
.shadow-sky {
  box-shadow: 0px 6px 10px rgba(98, 115, 143, 0.1);
}
.shadow-pmenu {
  box-shadow: 0px 0px 0px 1px rgba(22, 44, 120, 0.08), 0px 16px 24px -8px rgba(22, 44, 120, 0.25);
}
.width-auto,
.ui.auto-width {
  width: auto !important;
  min-width: auto !important;
}
.ui.auto-height {
  height: auto !important;
  min-height: auto !important;
}
.trash.icon:hover,
span.error-text {
  color: #f64c66;
}
.import-csv {
  cursor: pointer;
  filter: grayscale(1);
}
.import-csv:hover {
  color: #2cbfca;
  filter: grayscale(0);
}
.space-between {
  justify-content: space-between !important;
}
.flex-end {
  justify-content: flex-end !important;
}
.flex-start {
  justify-content: flex-start !important;
}
.flex-center {
  justify-content: center !important;
}
.align-items-inherit {
  align-items: inherit !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.overflow-auto {
  overflow: auto;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.smooth-scroll {
  scroll-behavior: smooth !important;
}
.float-left {
  float: left !important;
}
.float-center {
  float: none !important;
}
.float-right {
  float: right !important;
}
.text-white,
.ui.container .question-button.text-white {
  color: white !important;
}
.text-white-1 {
  color: #fcfcfc !important;
}
.text-primary,
.hover-text-primary:hover {
  color: #2cbfca !important;
}
.text-primary-1,
.ui.checkbox.toggle.text-primary-1 label,
.ui.toggle.checkbox.text-primary-1 input:checked + label {
  color: #759496 !important;
}
.text-primary-2 {
  color: #93cdd1 !important;
}
.text-primary-3 {
  color: #29b4be !important;
}
.text-primary-4 {
  color: #50e293;
}
.black-text,
.text-black {
  color: #000000 !important;
}
.text-black-1 {
  color: #383838 !important;
}
.text-black-2 {
  color: #3a3a3a !important;
}
.text-black-3,
.text-blue-10 {
  color: #03292c !important;
}
.text-black-4 {
  color: #828282 !important;
}
.text-black-5 {
  color: #333333 !important;
}
.text-black-6 {
  color: #111111 !important;
}
.text-gray {
  color: gray !important;
}
.text-gray-1 {
  color: #7c7c7c !important;
}
.text-gray-2 {
  color: #878787 !important;
}
.text-gray-3 {
  color: #595959 !important;
}
.text-gray-4 {
  color: #959595 !important;
}
.text-gray-5 {
  color: #515151 !important;
}
.text-gray-6 {
  color: #5a5a5a !important;
}
.text-gray-7 {
  color: #393939 !important;
}
.text-gray-8 {
  color: #afafaf !important;
}
.text-gray-9 {
  color: #bfbfbf !important;
}
.text-gray-10 {
  color: #9b9b9b !important;
}
.text-gray-11 {
  color: #c0c0c0;
}
.text-gray-12 {
  color: #e9e9e9 !important;
}
.text-gray-13 {
  color: #c9c9c9 !important;
}
.text-gray-14 {
  color: #979797 !important;
}
.text-gray-15 {
  color: #bdbdbd !important;
}
.text-gray-16 {
  color: #f2f2f2 !important;
}
.text-gray-17 {
  color: #767676 !important;
}
.text-gray-18 {
  color: #4f4f4f !important;
}
.text-gray-19 {
  color: #7d9596 !important;
}
.text-gray-20 {
  color: #797979 !important;
}
.text-blue {
  color: blue !important;
}
.text-blue-1 {
  color: #4879df !important;
}
.text-blue-2 {
  color: #253858 !important;
}
.text-blue-3 {
  color: #cee6e6 !important;
}
.text-blue-4 {
  color: #6b7c93 !important;
}
.text-blue-5 {
  color: #3b88f5 !important;
}
.text-blue-6 {
  color: #b1cffb !important;
}
.text-blue-7 {
  color: #627b7c !important;
}
.text-blue-8 {
  color: #01292c !important;
}
.text-blue-9 {
  color: #095b61 !important;
}
.text-blue-11 {
  color: #9abced !important;
}
.text-blue-12 {
  color: #4786f0 !important;
}
.text-blue-13 {
  color: #465e9f !important;
}
.text-blue-14 {
  color: #123163 !important;
}
.text-blue-15 {
  color: #424a58 !important;
}
.text-blue-16 {
  color: #7cb1ff !important;
}
.text-blue-17 {
  color: #357aeb !important;
}
.text-blue-18 {
  color: #2f80ed !important;
}
.text-blue-19 {
  color: #2351f6;
}
.text-pink-1 {
  color: #ff009f !important;
}
.text-red-1 {
  color: #e8586f !important;
}
.text-red-2,
i.icon.text-red-2,
.text-pink,
.text-red-2 .ui.checkbox label {
  color: #fa3666 !important;
}
.text-red-3 {
  color: #ffa8b5 !important;
}
.text-red-4 {
  color: #f47c7c !important;
}
.text-red-5 {
  color: #eb5757 !important;
}
.text-red-6 {
  color: #ff83a1 !important;
}
.text-red-7 {
  color: #ffc8d1 !important;
}
.text-red-8 {
  color: #f64c66;
}
.text-red-9 {
  color: #f55c4b;
}
.text-success-1 {
  color: #32d090 !important;
}
.text-success-2 {
  color: #adf4d7 !important;
}
.text-success-3 {
  color: #00dfd2 !important;
}
.text-success-4 {
  color: #aff9e3 !important;
}
.text-green {
  color: green !important;
}
.text-green-1 {
  color: #32c18e !important;
}
.text-green-2 {
  color: #9dae56 !important;
}
.text-green-3 {
  color: #27ae60 !important;
}
.text-green-4 {
  color: #0d2f06 !important;
}
.text-orange {
  color: orange !important;
}
.text-orange-1 {
  color: #f2994a !important;
}
.text-maroon {
  color: maroon !important;
}
.text-purple {
  color: purple !important;
}
.text-purple-1 {
  color: #9b51e0 !important;
}
.text-yello {
  color: yellow !important;
}
.text-yello-1 {
  color: #ffbb28 !important;
}
.color-112260 {
  color: #112260 !important;
}
.color-646F98 {
  color: #646f98 !important;
}
.color-32c18e {
  color: #32c18e !important;
}
.text-2E62E9 {
  color: #2e62e9 !important;
}
.bg-EAEFFD {
  background-color: #eaeffd !important;
}
.bg-yellow {
  background: #ffeb3b !important;
}
.bg-yellow-1 {
  background: #e0d999 !important;
}
.bg-yellow-2 {
  background-color: #ff9800 !important;
}
.bg-yellow-3 {
  background-color: #fff8d7 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-primary {
  background-color: #239fb5 !important;
}
.bg-primary-1 {
  background-color: #00c2ce !important;
}
.bg-primary-2 {
  background-color: #ecf8f8;
}
.bg-blue {
  background-color: #3b88f5 !important;
}
.bg-blue-1 {
  background-color: #465e9f !important;
}
.bg-blue-2 {
  background-color: #1988f7 !important;
}
.bg-blue-3 {
  background-color: #1a73e8 !important;
}
.bg-black-1 {
  background-color: #333333 !important;
}
.bg-black-2 {
  background-color: #666666 !important;
}
.bg-black-3 {
  background-color: #4a4a4a !important;
}
.bg-sky-1 {
  background-color: #f2f7f8 !important;
}
.bg-sky-2,
.border-blue-2px.bg-sky-2 {
  background-color: #eef4fd !important;
}
.bg-sky-3 {
  background-color: #fafbff !important;
}
.bg-sky-4 {
  background: #f3f9fa !important;
}
.bg-sky-5 {
  background-color: #f1fff8 !important;
}
.bg-success {
  background-color: #32d090 !important;
}
.bg-red,
.ui.container .bg-red.question-button {
  background-color: #fa3666 !important;
}
.bg-red-1 {
  background-color: #ff1970;
}
.bg-red-2 {
  background-color: #ff4949;
}
.bg-lightpink {
  background-color: #fff3f3 !important;
}
.bg-lightpink-1 {
  background-color: #eaceea !important;
}
.bg-gray {
  background-color: gray;
}
.bg-gray-1 {
  background-color: #f8faf9 !important;
}
.bg-gray-2,
.h-bg-gray-2:hover,
button.ui.button.bg-gray-2,
button.ui.basic.button.bg-gray-2 {
  background-color: #f6f6f6 !important;
}
.bg-gray-3 {
  background-color: #e7ebeb !important;
}
.bg-gray-4 {
  background-color: #e6e8e7 !important;
}
.bg-gray-5 {
  background-color: #bcc4c5 !important;
}
.bg-gray-6 {
  background-color: #e9e9e9 !important;
}
.bg-gray-7 {
  background-color: #f1f1f1 !important;
}
.bg-gray-8,
.bg-custom-gray-8 .ui.selection.dropdown {
  background-color: #f2f2f2 !important;
}
.bg-gray-9 {
  background-color: #f8f8f8 !important;
}
.bg-gray-10 {
  background-color: #f7f7f7 !important;
}
.bg-gray-11 {
  background-color: #fcfcfc !important;
}
.bg-gray-12 {
  background-color: #bdbdbd !important;
}
.bg-gray-13 {
  background-color: #dadada !important;
}
.bg-gray-14 {
  background-color: #e6efff !important;
}
.bg-gray-15 {
  background-color: #eeeeee !important;
}
.bg-fafafa {
  background-color: #fafafa !important;
}
.bg-lighteal {
  background-color: #c3d6d8 !important;
}
.bg-darkteal {
  background-color: #94adaf !important;
}
.bg-purple {
  background-color: purple !important;
}
.bg-purple-1 {
  background-color: #9b51e0 !important;
}
.bg-purple-2 {
  background-color: #d98df5 !important;
}
.bg-purple-3 {
  background-color: #f9e9ff !important;
}
.bg-green {
  background-color: green !important;
}
.bg-green-1 {
  background-color: #b9c978 !important;
}
.bg-green-2 {
  background-color: #19e09d !important;
}
.bg-green-3,
.h-bg-green-3:hover {
  background-color: #d9fffd !important;
}
.bg-orange {
  background-color: orange !important;
}
.bg-orange-1 {
  background-color: #f2994a !important;
}
.bg-azure {
  background-color: azure !important;
}
.bg-azure-1 {
  background-color: #eaffff !important;
}
.border-primary,
.pricing .ui.cards > .card.border-primary {
  border: 1px solid #239fb5 !important;
}
.border-primary-1 {
  border: 1px solid #2cbfca !important;
  outline: none;
}
.border-black {
  border: 1px solid #383838 !important;
}
.border-gray-1 {
  border: 1px solid #e0e0e0;
}
.border-gray-2 {
  border: 1px solid #dfe3e1 !important;
}
.border-gray-3 {
  border: 1px solid #d9d9d9 !important;
}
.border-gray-4 {
  border: 1px solid #c9c9c9;
}
.border-gray-5 {
  border: 1px solid #bdbdbd;
}
.border-teal {
  border: 1px solid #095b61 !important;
}
.border-black-2px,
.grid-item.border-blue-2px {
  border: 2px dashed #333333 !important;
}
.border-black-2px,
.grid-item.border-black-2px {
  border: 2px dashed #333333 !important;
}
.border-blue-1px {
  border: 1px dashed #2351f6;
}
.border-red,
.card-item.border-red {
  border: 1px solid #fa3666 !important;
}
.border-1px {
  border: 1px solid transparent;
}
.border-3px {
  border: 3px solid transparent;
}
.border-blue {
  border: 1px dashed #2351f6;
}
.border-blue-2px {
  border: 2px solid #1a73e8 !important;
}
.border-blue-3px,
.h-border-blue-3px:hover {
  border: 3px solid #2351f6;
}
.border-purple-1 {
  border: 1px solid #9b51e0 !important;
}
.bt-gray-1 {
  border-top: 1px solid #e9e9e9 !important;
}
.bt-pink-6 {
  border-top: 6px solid #ffa8b5 !important;
}
.bt-blue {
  border-top: 1px dashed #2351f6;
}
.bl-2x {
  border-left: 2px solid;
}
.bl-4px {
  border-left: 4px solid;
}
.bl-blue {
  border-left: 1px dashed #2351f6 !important;
}
.bb-gray-1 {
  border-bottom: 1px solid #e9e9e9 !important;
}
.bb-blue {
  border-bottom: 1px dashed #2351f6;
}
.bb-gray-1px {
  border-bottom: 1px solid #c9c9c9;
}
.bb-gray-1-2px {
  border-bottom: 2px solid #e0e0e0 !important;
}
.font-inherit {
  font-size: inherit !important;
}
.font-size-08 {
  font-size: 0.8rem !important;
}
.font-size-09 {
  font-size: 0.9rem !important;
}
.font-size-1 {
  font-size: 1rem !important;
}
.font-size-12 {
  font-size: 1.2rem !important;
}
.font-size-10px {
  font-size: 10px !important;
}
.font-size-11px {
  font-size: 11px !important;
}
.font-size-12px {
  font-size: 12px !important;
}
.font-size-13px {
  font-size: 13px !important;
}
.font-size-14px {
  font-size: 14px !important;
}
.font-size-15px {
  font-size: 15px !important;
}
.font-size-16px {
  font-size: 16px !important;
}
.font-size-18px,
.font-size-18px .ant-input {
  font-size: 18px !important;
}
.font-size-20px {
  font-size: 20px !important;
}
.font-size-22px {
  font-size: 22px !important;
}
.font-size-24px {
  font-size: 24px !important;
}
.font-size-26px {
  font-size: 26px !important;
}
.font-size-32px {
  font-size: 32px !important;
}
.font-large {
  font-size: large;
}
.font-bolder {
  font-weight: bolder;
}
.text-top {
  vertical-align: text-top;
}
.vl-middle {
  vertical-align: middle;
}
.capitalize {
  text-transform: capitalize;
}
.opacity-1 {
  opacity: 1 !important;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-75,
.h-opacity-75:hover {
  opacity: 0.75;
}
.resize-none,
.resize-none textarea {
  resize: none !important;
}
.w-10,
.ui.grid > .row > .column.w-10 {
  width: 10% !important;
}
.w-15,
.ui.grid > .row > .column.w-15 {
  width: 15% !important;
}
.w-7 {
  width: 7% !important;
}
.width-25,
.ui.container.width-25,
.w-25 {
  width: 25% !important;
}
.w-20 {
  width: 20% !important;
}
.w-22,
.ui.grid > .row > .column.w-22 {
  width: 22% !important;
}
.w-30,
.ui.w-30 {
  width: 30% !important;
}
.w-40,
.ui.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-78,
.ui.grid > .row > .column.w-78 {
  width: 78% !important;
}
.w-80,
.w-80.ui {
  width: 80% !important;
}
.w-90,
.width-90 {
  width: 90%;
}
.w-100,
.width-100 {
  width: 100%;
}
.w-20px {
  width: 20px;
}
.w-27px {
  width: 27px !important;
}
.w-40px {
  width: 40px !important;
}
.w-60px {
  width: 60px !important;
}
.w-150px {
  width: 150px !important;
}
.width-300px {
  width: 300px !important;
}
.width-320px {
  width: 320px !important;
}
.width-420px {
  width: 420px !important;
}
.width-100px {
  width: 100px;
}
.width-110px {
  width: 110px;
}
.width-220px {
  width: 220px !important;
}
.w-180px {
  width: 180px !important;
}
.width-20vw {
  width: 20vw !important;
}
.maxw-25 {
  max-width: 25% !important;
}
.maxw-33 {
  max-width: 33% !important;
}
.maxw-46_5 {
  max-width: 46.5% !important;
}
.maxw-320px {
  max-width: 320px;
}
.mw-fit-content {
  min-width: fit-content !important;
}
.mw-min-content {
  min-width: min-content !important;
}
.mw-max-content {
  min-width: max-content !important;
}
.minw-25 {
  min-width: 25% !important;
}
.mw-30 {
  min-width: 30% !important;
}
.minw-33 {
  min-width: 33% !important;
}
.mw-35 {
  min-width: 35% !important;
}
.mw-42_5 {
  min-width: 42.5% !important;
}
.mw-46_5 {
  min-width: 46.5% !important;
}
.mw-50 {
  min-width: 50% !important;
}
.mw-36px {
  min-width: 36px !important;
}
.mw-110px {
  min-width: 110px !important;
}
.mw-120px {
  min-width: 120px !important;
}
.mw-175px {
  min-width: 175px !important;
}
.mw-200px {
  min-width: 200px !important;
}
.mw-260px {
  min-width: 260px !important;
}
.minw-320px {
  min-width: 320px;
}
.minw-400px {
  min-width: 400px;
}
.h-auto {
  height: auto !important;
}
.h-90vh {
  height: 90vh;
}
.h-100vh {
  height: 100vh;
}
.h-100 {
  height: 100% !important;
}
.h-16px {
  height: 16px;
}
.h-20px {
  height: 20px !important;
}
.h-27px {
  height: 27px !important;
}
.h-40px {
  height: 40px !important;
}
.h-45px {
  height: 45px !important;
}
.h-56px {
  height: 56px !important;
}
.height-80px {
  height: 80px !important;
}
.height-100px {
  height: 100px;
}
.height-200px {
  height: 200px;
}
.h-300px {
  height: 300px;
}
.mh-36px {
  min-height: 36px;
}
.min-height-50px {
  min-height: 50px;
}
.min-height-55px {
  min-height: 55px !important;
}
.min-height-62px {
  min-height: 62px !important;
}
.mh-110px {
  min-height: 110px !important;
}
.minh-200px {
  min-height: 200px !important;
}
.min-height-25vh {
  min-height: 25vh !important;
}
.min-height-50vh {
  min-height: 50vh !important;
}
.max-height-9px {
  max-height: 9px !important;
}
.max-height-12px {
  max-height: 12px !important;
}
.max-height-14px {
  max-height: 14px !important;
}
.max-height-20px {
  max-height: 20px !important;
}
.max-height-27px {
  max-height: 27px !important;
}
.max-height-36px {
  max-height: 36px !important;
}
.max-height-62px {
  max-height: 62px !important;
}
.mh-50px {
  max-height: 50px !important;
}
.mh-100px {
  max-height: 100px !important;
}
.mh-150px {
  max-height: 150px !important;
}
.mh-100 {
  max-height: 100% !important;
}
.mh-200px {
  max-height: 200px !important;
}
.mh-300px {
  max-height: 300px !important;
}
.max-height-50px {
  max-height: 50px !important;
}
.mw-20px {
  max-width: 20px !important;
}
.max-width-40px {
  max-width: 40px !important;
}
.max-width-60px {
  max-width: 60px !important;
}
.mw-90px {
  max-width: 90px !important;
}
.mw-100px {
  max-width: 100px !important;
}
.mw-100 {
  max-width: 100%;
}
.mw-300px {
  max-width: 300px !important;
}
.mw-400px {
  max-width: 400px !important;
}
.mw-500px {
  max-width: 500px !important;
}
.mw-auto,
.ui.mw-auto {
  min-width: auto !important;
  max-width: none !important;
}
.border-radius-0,
.ui.card > .content.border-radius-0 {
  border-radius: 0 !important;
}
.br-4px,
.border-radius-4,
.br-4px-antmodal .ant-modal-content {
  border-radius: 4px !important;
}
.br-2px,
.border-radius-2px {
  border-radius: 2px;
}
.br-5px {
  border-radius: 5px;
}
.br-6px,
.border-radius-6px {
  border-radius: 6px !important;
}
.br-10px,
.border-radius-10px,
.ant-modal.br-10px .ant-modal-content {
  border-radius: 10px !important;
}
.br-12px {
  border-radius: 12px;
}
.br-20px,
.border-radius-20px {
  border-radius: 20px !important;
}
.br-50px {
  border-radius: 50px;
}
.top-3 {
  top: 1.5rem !important;
}
.top-4px {
  top: 4px;
}
.right-3 {
  right: 1.5rem !important;
}
.three-dots,
.ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.test-saving {
  color: #383838;
  font-size: 12px;
}
.ui.image {
  max-height: 100%;
  max-width: 100%;
}
.avatar {
  height: 34px;
  width: 34px;
  background: #465e9f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.lh-0 {
  line-height: 0 !important;
}
.lh-1 {
  line-height: 1;
}
.lh-125 {
  line-height: 1.25;
}
.line-height-15 {
  line-height: 1.5;
}
.line-height-18px,
.lh-18px {
  line-height: 18px !important;
}
.line-height-20px {
  line-height: 20px !important;
}
.line-height-22px {
  line-height: 22px !important;
}
.lh-28px {
  line-height: 28px !important;
}
.lh-30px {
  line-height: 30px !important;
}
.lh-35px {
  line-height: 35px !important;
}
.lh-40px {
  line-height: 40px !important;
}
.brightness-0 {
  filter: brightness(0%);
}
.pull-right {
  float: right;
}
.visibility-none,
.visibility-hidden {
  visibility: hidden;
}
.valign-baseline {
  vertical-align: baseline;
}
ul.list-style-none {
  list-style-type: none;
}
.ui.toggle.checkbox.primary input:checked ~ label:before {
  background-color: #2cbfca !important;
}
.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: #2cbfca;
  border-color: #2cbfca;
}
.ui.checkbox input:checked ~ label:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: white;
}
.card-box {
  padding: 0.5rem 1rem !important;
  max-height: 64px;
  overflow: auto;
  height: 64px;
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}
button.ui.active.filter-btn {
  border: 1px solid #a9d0d2 !important;
  box-shadow: 0px 5px 10px rgba(124, 139, 134, 0.5);
  background: #dcf1f2 !important;
}
button.ui.active.filter-btn .filter-label {
  min-width: 15px;
  min-height: 15px;
  padding: 0 !important;
  line-height: 14px;
  margin-left: 0.5rem;
  background-color: #f0953e;
  color: #ffffff;
}
button.ui.filter-apply {
  background: #32c18e !important;
  color: white !important;
  border: 1px solid #32c18e !important;
  line-height: 18px;
  padding: 0.5em 1rem !important;
  font-family: "Roboto", sans-serif !important;
  margin: auto;
}
.cursor-default {
  cursor: default !important;
}
.ls-02 {
  letter-spacing: 0.2px;
}
.ls-05 {
  letter-spacing: 0.5px;
}
.rotate-90 {
  transform: rotate(90deg);
}
.ui.card-instructions.list .item:hover {
  color: #03292c !important;
}
.grayscale-1 {
  filter: grayscale(1);
}
.transition {
  transition: all 0.25s ease-in-out;
}
.text-wrap-balance {
  text-wrap: balance !important;
}
.creation-tab-width {
  width: min(100%, 820px);
}
.ant-radio-group.monthly-sub-options label.ant-radio-button-wrapper:first-child {
  border-radius: 2.5rem 0 0 2.5rem;
}
.ant-radio-group.monthly-sub-options label.ant-radio-button-wrapper:last-child {
  border-radius: 0 2.5rem 2.5rem 0;
}
/* Login/Sign Up form */
.ui.grid.login-sign-form {
  padding: 7.5rem 0 1rem !important;
}
.login-sign-form .ui.form .fields .field .ui.input input {
  width: 95%;
}
.login-sign-form .ui.form .field > label {
  font-weight: normal;
}
.login-sign-form .left-side h2,
.login-sign-form .left-side h5 {
  font-family: "Montserrat", sans-serif !important;
}
.login-sign-form h2.header-text {
  font-size: 28px;
}

/* Test Details */
.menu-btns.ui.buttons .button {
  border: 1px solid #e9e9e9;
  border-left: 0;
  border-radius: 0 !important;
}
.menu-btns.ui.buttons .button:first-child {
  border-left: 1px solid #e9e9e9;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.menu-btns.ui.buttons .button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.menu-btns.ui.basic.buttons button.ui.active.button {
  background-color: #ddf8f9 !important;
  color: #095b61 !important;
  border: 1px solid #29b4be;
}

.card-types .card:hover,
.card-types .card.active {
  border: 1px solid #2cbfca;
  transition: all 0.2s ease-in;
}
.card-types .card.active .anticon-check-circle {
  top: 0.5rem;
  right: 0.5rem;
  font-size: 18px;
  color: #2cbfca;
  position: absolute;
}
.ui.toggle.checkbox.primary input:focus:checked ~ label:before,
.ui.toggle.checkbox.primary input:checked ~ label:before {
  background-color: #2cbfca !important;
}

/* Card/Category Setup */
.card-item {
  gap: 0.5rem;
  padding: 1rem;
  min-height: auto;
  border-radius: 6px;
  position: relative;
  align-items: center;
  background: white;
  display: flex !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid #c9c9c9 !important;
}
.sort-item:last-child,
.card-item:last-child,
.expanded .sort-item:first-child {
  margin-bottom: 0 !important;
}
.card-item.error,
.bl-5px-error {
  border-left: 5px solid #fa3666 !important;
}
.card-item:hover {
  cursor: grabbing;
  background: #edf2f2;
}
.card-item:hover .delete-item {
  visibility: visible;
}
.sortable-ghost .card-item {
  background: #eff4ff;
  border: 2px dashed #3b88f5;
}
.card-item .ui.input {
  width: 87.5%;
}
.card-item .ant-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
  line-height: 20px !important;
}
.card-item .ui.input input,
.card-item .item-name {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #095b61;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
  max-width: calc(100% - 40px - 2rem);
  min-width: 50%;
}
.card-item .ui.image.card-close,
.ui.image.popup-close {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.card-item .droppable,
.card-item .card-image,
.image-container .droppable {
  height: 30px !important;
  width: 30px !important;
  background-size: cover;
  background-image: url(../images/image@2x.png);
}
.card-item .droppable,
.image-container .droppable {
  width: 40px !important;
  height: 40px !important;
}
/* .card-item.error .droppable {
  border: 1px dashed #fa3666;
  border-radius: 5px;
} */
.card-item .droppable img,
.image-container .droppable img {
  border-radius: 4px;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #e0e0e0;
}
.card-item .droppable .uploadImgDiv,
.image-container .droppable .uploadImgDiv {
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  font-size: 8px;
  line-height: 9px;
  color: #fff;
  font-weight: bold;
  opacity: 0;
  font-family: "Roboto", sans-serif;
  transition: all 0.2s ease-in-out;
}
.card-item .droppable .uploadImgDiv:hover,
.image-container .droppable .uploadImgDiv:hover {
  opacity: 1;
  background: #666666;
}
.grid-item {
  border: 1px solid #c9c9c9 !important;
  border-radius: 2px !important;
  position: relative !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15) !important;
}
.sortable-chosen.sortable-ghost {
  visibility: hidden;
  position: relative;
}
.sortable-chosen.sortable-ghost > .placeholder {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: visible;
  border-radius: 2px;
  /* box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15); */
}
.sortable-chosen > .ui.card,
.sortable-chosen > .card-item,
.react-grid-layout .react-draggable-dragging .grid-item {
  width: inherit;
  /* transform: rotate(-9deg); */
  background-color: #f5feff;
  box-shadow: 0px 8px 10px rgb(184 196 216 / 50%);
}
.creation .sortable-chosen > .card-item {
  /* transform: rotate(2.09deg); */
  border: 1px solid #c9c9c9 !important;
  background-color: #edf2f2 !important;
}
.creation .sortable-swap-highlight {
  border: 2px dashed #3b88f5;
  background: #eff4ff;
  border-radius: 2px;
  opacity: 0.5;
}
.h-100::-webkit-scrollbar-thumb {
  background: #abbec0 !important;
}
.react-grid-item.react-grid-placeholder {
  opacity: 1 !important;
  background-color: #94adaf !important;
  border-radius: 2px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}
.item-placeholder {
  background: #fafbff;
  border: 2px dashed #3b88f5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 100%;
  padding: 1rem;
  position: relative;
  text-align: center;
}
.item-placeholder .placeholder-content {
  background: #eef4fd;
  border: 2px dashed #3b88f5;
  box-sizing: border-box;
  border-radius: 2px;
  min-height: 62px;
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 2rem);
}
.mr-2.mw-30.mh-100:last-child,
.mr-2.mw-35.mh-100:last-child {
  padding-right: 1rem !important;
}

.image-upload .is-dragging {
  background: rgba(1, 13, 14, 0.8);
  border: 3px dashed #7febee;
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #9dfcff;
  font-weight: bold;
}
.image-upload .img-preview:hover > .ui.container.backdrop {
  visibility: visible;
}
.ui.container.backdrop {
  position: absolute;
  visibility: hidden;
  background: #010d0e;
  opacity: 0.5;
  height: 100%;
  z-index: 100;
  text-align: right;
  padding-right: 1rem;
  padding-top: 0.5rem;
  border-radius: 2px;
}

.ui.pagination.menu {
  border: none;
  box-shadow: none;
}
.ui.pagination.menu .item {
  width: 26px;
  height: 26px;
  min-width: auto;
  justify-content: center;
  padding: 0 !important;
  outline: none;
}
.ui.pagination.menu .active.item {
  color: white;
  background: #2cbfca;
  border-radius: 50%;
}
.ui.table:not(.default) {
  border-radius: 0;
  border: 1px solid #dfe3e1;
  font-family: "Roboto", sans-serif !important;
}
.ui.table thead th {
  background-color: transparent;
}
.ui.table thead th:first-child,
.ui.table tbody td:first-child {
  padding-left: 1rem;
}
/* .ui.table thead th:last-child,
.ui.table tbody td:last-child {
  padding-right: 1.5rem;
} */
.ui.table:not(.default) thead th {
  color: #759496;
  border: 0;
  font-size: 12px;
}
.ui.table:not(.default) td {
  color: #383838;
  border: 0 !important;
  line-height: 16px;
  padding: 0.7rem;
}
.ui.selectable.table:not(.default) tbody tr:hover,
.ui.table:not(.default) tbody tr:hover {
  background: #f3f9fa !important;
}
.ui.table:not(.default) tbody tr:hover td {
  color: #03292c;
  font-weight: 500;
}
table.ui.table.row-borders tr td,
table.ui.table.row-borders tr th {
  border-bottom: 1px solid #dfe3e1 !important;
}
.ui.selectable.table:not(.default) tbody td.positive,
.ui.selectable.table:not(.default) tbody tr.positive {
  background: #caf9ff !important;
  font-weight: 800;
}
.ui.inverted.page.modals.dimmer.transition.visible.active {
  background-color: rgba(255, 255, 255, 0.85);
}
.ui.modal > i.close.icon {
  top: 0.5rem;
  right: 0.5rem;
  color: black;
  margin: 0;
}
.ui.modal .close-icon {
  position: absolute !important;
  top: 1rem;
  right: 1rem;
  margin: 0 !important;
  max-height: 1rem !important;
  cursor: pointer;
}
.pricing .popular-label {
  position: absolute;
  top: 0.75rem;
  right: -2.1rem;
  background: #4879df;
  padding: 0.4rem 2rem;
  transform: rotateZ(45deg);
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 0 !important;
}
.pricing .save-money .discount-text {
  top: -12px;
  right: -24px;
  font-size: 12px;
  position: relative;
  transform: rotate(-8deg);
}
.all-tests-screen {
  min-height: calc(100vh - 64px - 64px - 59px);
}
.free-trial {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #2196f3;
  text-align: center;
  padding: 1rem;
  color: white;
}
.position-place {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* payments */
.payments .ui.cards.tools > .card {
  width: 303px;
}
.pricing .ui.cards > .card {
  width: 230px;
  min-height: 417px;
  border: 1px solid #dfdfdf !important;
  box-shadow: none;
  padding: 3rem 1.5rem 1.5rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: none !important;
  transform: none !important;
}
.pricing .ui.cards > .card.selected {
  border: 3px solid #4879df !important;
}
.payments .ui.cards > .card > .content {
  border-radius: 10px !important;
  border: 1px solid #dfdfdf !important;
}
.payments .ui.cards > .card.active > .content {
  border: 3px solid #4879df !important;
}
.payments .ui.cards > .card > .content .img_icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #e9ebff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  /* padding: 0.5rem 0.5rem 0.25rem !important; */
}
.payments .ui.cards > .card > .content a.button,
.cancel-modal .ui.blue.button {
  border: 1px solid #3976ff !important;
  background: #3976ff;
}
.payments .ui.cards.tools > .card > .content a.button.basic,
.payments .ui.cards.tools > .card > .content a {
  color: #3976ff !important;
}
.payments .ui.cards.tools > .card > .content a.button {
  color: white !important;
}
.payments .ui.cards.subscriptions > .card > .content a.button,
.cancel-modal .ui.button,
.pricing .ui.cards > .card > button {
  border-radius: 4px !important;
  font-size: 12px;
  padding: 0.8rem 1.9rem;
  font-family: "Montserrat", sans-serif !important;
}
.payments .ui.cards > .card > .content button,
.payments .ui.cards > .card > .content a,
.pricing .ui.cards > .card > button {
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
}
.cancel-modal .ui.button {
  padding: 1rem 3.25rem !important;
  font-weight: bold;
}
.cancel-modal.ui.basic.button {
  color: #8e8e8e !important;
  border: 1px solid;
  box-shadow: none;
}
.payments .payment-history {
  min-height: 25vh;
  max-height: 70vh;
  overflow: hidden auto;
}
.payments .plan-unsubscribe {
  position: absolute;
  top: 5px;
  right: -5px;
  font-weight: normal !important;
}
.payments label.plan-label {
  position: absolute;
  top: 0;
  left: 0;
  background: #2cbfca;
  border-top-left-radius: 10px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  font-family: "Montserrat", sans-serif;
}
.payments label.plan-label:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 36px solid white;
  border-left: 15px solid #43bfca;
}
.pricing .btn-group .ui.buttons .button {
  font-size: 16px;
  font-family: "Montserrat", sans-serif !important;
  background: transparent !important;
  border: 1px solid #979797 !important;
  box-shadow: none !important;
  color: #363636 !important;
}
.pricing .btn-group .ui.buttons .button.active {
  border: 1px solid #239fb5 !important;
  background: #239fb5 !important;
  color: #ffffff !important;
}
.pricing .btn-group .ui.buttons .button:first-child {
  border-radius: 2.5rem 0 0 2.5rem !important;
  border-right: 0 !important;
}
.pricing .btn-group .ui.buttons .button:last-child {
  border-radius: 0 2.5rem 2.5rem 0 !important;
  border-left: 0 !important;
  margin-left: -1px !important;
}

/* Tests Index */
.sidebar {
  border-right: 1px solid #e9e9e9;
  min-height: 100vh;
}
.br-blue {
  border-right: 1px dashed #2351f6;
}
.sidebar .ui.vertical.menu {
  height: calc(100% - 62px);
}
.sidebar .ui.vertical.menu > a.item {
  color: #9b9b9b;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  margin: 0 !important;
  padding: 0.75rem 1.25rem;
  border-radius: 0 !important;
  border-left: 5px solid transparent;
  width: calc(100% - 20px);
}
.sidebar .ui.vertical.menu a.active.item {
  border-left: 5px solid #239fb5;
  color: #239fb5 !important;
  background: #f0ffff !important;
}
.sidebar .ui.vertical.menu a.item.logout-btn,
.sidebar li.ant-menu-item.logout-btn {
  bottom: 0;
  position: absolute;
}
.sidebar .ui.vertical.menu a.item .ui.image {
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
}
.tests .ui.dividing.header,
.tests .ui.divider {
  border-bottom: 1px solid #e9e9e9 !important;
}
.ui.grid > .row.tests-body {
  border: 1px solid #dfe3e1;
  background: #ffffff;
  border-radius: 2px;
  padding: 5rem 0;
}
.test-setup .ui.grid:first-child {
  margin: 0;
}
.test-creation.test-setup .ui.grid:first-child > .wide.column:first-child {
  flex: 0.15 1 !important;
}
.test-setup .ui.grid:first-child > .wide.column:first-child {
  padding: 0;
  flex: 0.15 1 !important;
  background-color: #f8faf9;
  min-height: calc(100vh - 54px - 67px);
}
.test-setup .ui.grid:first-child > .wide.column:last-child {
  border-top: 1px solid #dfe3e1;
  border-left: 1px solid #dfe3e1;
  padding: 0.5rem 1.5rem 1.5rem;
  flex: 1 !important;
}
.test-setup.test-creation .ui.grid:first-child > .wide.column:last-child {
  padding: 2rem 2rem 0;
}
.test-setup .ui.vertical.menu {
  border: 0;
  background: transparent !important;
  box-shadow: none;
}
.test-setup .ui.vertical.menu .item {
  gap: 0.5rem;
  display: flex;
  font-size: 12px;
  align-items: center;
  flex-direction: row;
  color: #9b9b9b !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-left: 5px solid transparent !important;
  font-family: "Montserrat", sans-serif !important;
}
.test-setup .ui.vertical.menu .active.item {
  background: #f0ffff;
  color: #239fb5 !important;
  font-weight: bold;
  border-left: 5px solid #239fb5 !important;
}
.test-setup .ui.vertical.menu .item:before {
  height: 0;
}
.test-setup .ui.vertical.menu .item img {
  max-height: 23px;
  filter: grayscale(1);
}
.test-setup .ui.vertical.menu .active.item img {
  filter: none;
}
.test-setup .ui.segment.tab {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  min-height: 10rem;
}
.tree-actions {
  display: flex;
  float: right;
  padding-top: 10px;
}
.status-icon img.ui.image {
  max-width: 20px;
}
.file-drop {
  height: 160px;
  border: 2px dashed #ebebeb;
  border-radius: 2px;
  padding-top: 1.5rem;
  text-align: center;
  outline: none;
  margin: 0.5rem auto;
}
.file-drop.active {
  color: #2cbfca;
  border: 2px dashed #2cbfca;
}
.ui.label.upload-files {
  border: 0.5px solid #8581ff;
  border-radius: 10.5px;
  background-color: #9b98ff;
  box-shadow: inset 0 0 3px 0 rgba(133, 155, 255, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.25rem 1rem;
}

/* Survey */
.question-form {
  border: 1px solid #e0e0e0;
  border-radius: 18px;
}
.question-form.active {
  border: 1px solid #9b51e0;
}
.post .question-form.active {
  border: 1px solid #ccde82;
}
.screener .question-form.active {
  border: 1px solid #ea7a16;
}
.question-form:hover {
  border: 1px solid #d98df5 !important;
}
.post .question-form:hover {
  border: 1px solid #ccde82 !important;
}
.screener .question-form:hover {
  border: 1px solid #f2994a !important;
}
.post .question-form .question-label {
  color: #9dae56;
  background-color: #f4f9e0;
}
.screener .question-form .question-label {
  color: #f2994a;
  background-color: #ffeede;
}
.question-form .question-label {
  background-color: #f8eefc;
  color: #9b51e0;
  padding: 0 2rem;
  border-radius: 2px;
  height: 40px;
  border-radius: 20px 18px 18px 0;
  font-size: 16px;
}
.question-button {
  color: #9b51e0 !important;
  background: transparent !important;
}
.question-button.hovered,
.question-button:hover {
  background-color: #f9e9ff !important;
}
.question-form.active .question-label,
.question-form:hover .question-label,
.question-button.active {
  color: white !important;
  background-color: #9b51e0 !important;
}
.post .question-button {
  color: #b9c978 !important;
}
.screener .question-button {
  color: #ea7a16 !important;
}
.post .question-button.hovered,
.post .question-button:hover {
  background-color: #f4f9e0 !important;
}
.post .question-form.active .question-label,
.post .question-form:hover .question-label,
.post .question-button.active {
  color: white !important;
  background-color: #b9c978 !important;
}
.screener .question-button.hovered,
.screener .question-button:hover {
  background-color: #ffeede !important;
}
.screener .question-form.active .question-label,
.screener .question-form:hover .question-label,
.screener .question-button.active {
  color: white !important;
  background-color: #ea7a16 !important;
}
.question-form .ui.input input,
.study-body.questionnaire .ui.input input {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.question-form .ui.input input:focus,
.study-body.questionnaire .ui.input input:focus {
  border-bottom: 1px solid #239fb5 !important;
}
.question-form .rating-points .ui.input input {
  border: 1px solid #bdbdbd !important;
  border-radius: 4px !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  color: #2cbfca;
  font-weight: bold;
}
.question-form .rating-points .ui.input input:focus {
  border: 1px solid #2cbfca !important;
}
.question-form .images .ant-tag:hover {
  color: #2cbfca !important;
  border: 1px solid #2cbfca !important;
  background-color: #f0ffff !important;
}
.ui.label:hover .visibility-none,
.survey-options:hover .visibility-none,
.question-form .images .ant-typography:hover .visibility-none {
  visibility: visible;
}
.question-form .q-select.ant-select .ant-select-selector {
  width: 200px;
  border: transparent;
  background: #f2f2f2;
  border-radius: 0 18px 18px 0;
}

/* Confirmation Modal */
.confirm-modal {
  max-width: 232px;
}
.confirm-modal .message {
  color: #383838;
  text-align: center;
  margin: 1.2rem auto;
}
.confirm-modal .ui.button {
  padding: 0.5rem 1rem;
  width: 90px;
  min-width: auto;
}

.marker-form {
  width: 22rem !important;
}
.marker-form textarea[readonly] {
  pointer-events: all !important;
}

.ReactCrop__crop-selection {
  background: rgba(255, 92, 129, 0.7);
  border-image-source: none;
  box-shadow: none;
  border: 2px solid #3c9aff;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
}
.ReactCrop__drag-handle {
  background-color: #fff;
  border: 2px solid #3c9aff;
}
.ReactCrop__image {
  border: 1px solid #e0e0e0;
}
.ReactCrop_addon,
.ReactCrop__selection-addon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.study .ReactCrop_addon,
.participate-responses .ReactCrop_addon {
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.participate-responses .ui.items > .item.task-item {
  border: 1px solid #dfe3e1;
  border-radius: 4px;
  padding: 2px;
  width: 150px;
  height: 62px;
  min-width: 150px !important;
}
.participate-responses .ui.items > .item.task-item .image {
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 53px;
}
.participate-responses .item.task-item.active {
  border: 1px solid #2cbfca !important;
}
.participate-responses .ReactCrop_addon.active {
  border: 3px solid #2cbfca !important;
  z-index: 1;
}

/* Card Sort Report */
.card-study .ui.progress .bar {
  background: black;
  border-radius: 10px;
  /* height: 10px; */
  min-width: auto;
}
.main-container {
  height: calc(100vh - 54px); /* Navbar height 54px */
}

@media screen and (max-width: 720px) {
  .marker-form {
    width: 85vw !important;
  }
  .ui.container.mb-hide,
  .mb-hide,
  .ui.borderless.menu .right.menu,
  .ui.grid.login-sign-form .left-side h5.header {
    display: none !important;
  }
  .ui.navbar.menu .item {
    margin-left: 0 !important;
  }
  .ui.grid.login-sign-form {
    padding: 1rem !important;
    margin: 0;
  }
  .login-sign-form h2.header-text {
    font-size: 22px;
  }
  .login-sign-form h2.header-text img {
    margin-right: 0.5rem !important;
    width: 2em !important;
  }
  .login-sign-form h2.header {
    margin-top: 0 !important;
  }
  .ui.grid.login-sign-form .wide.column {
    width: 100% !important;
    padding: 0 !important;
  }
  .ui.grid.login-sign-form .wide.column:last-child {
    box-shadow: none;
  }
  .ui.grid.login-sign-form .wide.column:last-child h2.header {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ui.container {
    width: calc(100% - 4rem);
  }
}

/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  outline: none !important;
  height: 40px !important;
  padding: 10px !important;
  width: 100% !important;
  color: #32325d !important;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  background-color: white !important;
  border: 1px solid #eeeeee !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
  -webkit-transition: box-shadow 150ms ease !important;
  transition: box-shadow 150ms ease !important;
}
.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.MyCardElement--invalid {
  border-color: #fa755a;
}
.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.MyCardElement::placeholder {
  color: #aab7c4 !important;
}
.MyCardIcon {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #424770;
}

.ui.fluid.container.preview-copy {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 15px;
  position: absolute;
  top: calc(57px + 1rem);
  width: 44%;
  min-width: auto !important;
  left: 28%;
  padding: 1rem;
  background: #ffffff;
  border-radius: 4px;
}
.ui.fluid.container.preview-copy .ui.header > img + .content {
  padding-left: 0;
}

.square-13px {
  width: 13px;
  height: 13px;
}
.result-matrix-details {
  padding: 8px 10px;
}
.card-highest-1 {
  background-color: #ffc077;
}
.card-highest-2 {
  background-color: #bae2e3;
}
.card-highest-3 {
  background-color: #e9fafb;
}
.card-highest-round {
  margin: 0 10px;
}
span.result-matrix-header-item-left-container {
  color: #28c083;
  background-color: #d2fbea;
  border-radius: 3px;
  padding: 5px 10px;
}
.result-matrix-datatable-container {
  overflow: auto;
  height: calc(45rem - 10px);
}
.result-matrix-datatable-container > table.ui.table {
  border-collapse: collapse !important;
}
.result-matrix-datatable-container > table.ui.table tr,
.result-matrix-datatable-container > table.ui.table td,
.result-matrix-datatable-container > table.ui.table th {
  border: 1px solid #dfe3e1 !important;
}
.result-matrix-datatable-container > table.ui.table img {
  width: 25px;
}
.categorisationConfidence tr th:not(:first-child),
.categorisationConfidence tr td:not(:first-child) {
  text-align: center !important;
  font-size: 18px;
}
.cat-conf-dd img.card-image,
.hw-40px {
  height: 40px !important;
  width: 40px !important;
  background-size: cover;
}
.cat-conf-dd .dropdown,
.cat-conf-dd div[role*="option"] {
  align-items: center !important;
  display: flex !important;
}
.unclear-cat {
  color: #ffc077;
}
.confidence_Low {
  color: #ffc077;
  font-weight: bold;
}
.white-space-nw {
  white-space: nowrap;
}
.white-space-pw {
  white-space: pre-wrap;
}
.text-overflow-elsis {
  text-overflow: ellipsis;
}
/* .react-transform-component,
.react-transform-element {
  height: 100%;
  width: 100%;
} */
.ls-sidebar {
  background: url("../../assets/images/login_sidebar.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.actions-row:hover,
.actions-row.active {
  border: 1px solid rgb(25, 136, 247) !important;
}
.pdetails .ant-modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.participant-details-tree .ant-tree .ant-tree-switcher {
  display: none;
}
.participant-details-tree.ant-tree .ant-tree-node-content-wrapper {
  background: none;
  line-height: 1;
  pointer-events: none;
  border: 0 !important;
}
.participant-details-tree.ant-tree .ant-tree-node-content-wrapper .ant-tree-title {
  font-size: 12px;
}

.ant-radio-group.reason-checks .ant-radio-wrapper {
  font-size: 12px;
  margin: 0 !important;
}

.survey-result .ant-table-footer {
  background-color: #f9fafb !important;
}

.test-study,
.test-study * {
  user-select: none !important;
}

/* Study Preview Header */
.test-study .preview-mode {
  top: 0;
  z-index: 101;
  position: sticky;
  color: #383838;
  background-color: #00f2b1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-family: "Roboto", sans-serif !important;
}

/* animations */
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes tada {
  0% {
    color: #00c2ce;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    color: #00c2ce;
    -webkit-transform: scale3d(0.75, 0.75, 0.75) rotate3d(0, 0, 1, -6deg);
    transform: scale3d(0.75, 0.75, 0.75) rotate3d(0, 0, 1, -6deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 6deg);
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 6deg);
  }

  40%,
  60%,
  80% {
    color: #00c2ce;
    -webkit-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -6deg);
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -6deg);
  }

  100% {
    color: black;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    color: #00c2ce;
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    color: #00c2ce;
    -webkit-transform: scale3d(0.75, 0.75, 0.75) rotate3d(0, 0, 1, -6deg);
    -ms-transform: scale3d(0.75, 0.75, 0.75) rotate3d(0, 0, 1, -6deg);
    transform: scale3d(0.75, 0.75, 0.75) rotate3d(0, 0, 1, -6deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 6deg);
    -ms-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 6deg);
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, 6deg);
  }

  40%,
  60%,
  80% {
    color: #00c2ce;
    -webkit-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -6deg);
    -ms-transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -6deg);
    transform: scale3d(1.25, 1.25, 1.25) rotate3d(0, 0, 1, -6deg);
  }

  100% {
    color: black;
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
