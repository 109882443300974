.study-header,
.study-body {
  padding: 1rem 10rem !important;
}
.in-preview .study-header.participate-study {
  padding-top: 3.5rem !important;
}
.in-preview .sticky.fixed .study-header.participate-study {
  padding-top: 0 !important;
}
.study-header {
  background: #f7f7f7;
  border-bottom: 2px solid #e0e0e0;
}
.study-header.tree-test {
  /* background: #ecf6f1; */
  border-bottom: 6px solid #2cbfca;
  padding-bottom: 3rem !important;
}
.guide-modal div.header {
  background-color: #f8faf9 !important;
}
.guide-modal .ui.image .ui.label {
  position: absolute;
  bottom: -20px;
  left: 25%;
  background: transparent;
}
.ui.items.user-comments {
  max-height: 160px;
  overflow: auto;
}
.ui.items.user-comments > .item > .content > .header:not(.ui) {
  font-size: 1rem;
}
.participate-study .ui.container.task-navs,
.participate-study .ui.container.task-marks,
.participate-study .ui.progress.task-progress-bar {
  width: 40%;
  min-width: auto !important;
}
.participate-study .ui.container.task-navs .ui.button {
  border-radius: 4px !important;
  border: 1px solid #dfe3e1;
  height: 28px;
  padding: 0;
  width: 46px !important;
  font-size: 1.6rem;
  box-shadow: none;
}
.participate-study .ui.container.task-navs .ui.button.basic {
  background-color: #f6f6f6 !important;
}
.participate-study .ui.divider {
  border-top: 1px solid #eeeeee !important;
  border-bottom: none !important;
}
.participate-study .task-marks button.ui.button {
  background-color: #fcfcfc;
  border: 4px solid #bdbdbd;
  width: 50px !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.participate-study .task-marks button.ui.button span {
  width: 42px;
  height: 42px;
  /* background: #ffe3e9; */
  border: 4px solid #ffffff;
  color: #bdbdbd;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.participate-study .task-marks button.ui.button.complete {
  border: 4px solid transparent;
}
.participate-study .task-marks button.ui.button.complete span {
  background: #bdbdbd;
  color: #fcfcfc;
}
.participate-study .task-marks button.ui.button.active {
  border: 4px solid #ff5c81;
}
.participate-study .task-marks button.ui.button.active span {
  background: #ff5c81;
  color: #ffffff;
}
.participate-study .task-marks button.ui.button.active.complete span {
  background: #2cbfca;
  color: #ffffff;
}
.participate-study .task-marks button.ui.button span i.icon {
  position: absolute;
  top: -2.5px;
  right: -3.5px;
  background: #ff5c81;
  color: white;
  padding: 0 !important;
  line-height: inherit !important;
  height: 16px !important;
  width: 16px !important;
  font-size: 0.7rem;
}
.participate-study .task-marks button.ui.button.complete span i.icon {
  background: #2cbfca;
}
.participate-study .ui.progress.task-progress-bar {
  background: #d9d9d9;
  border-radius: 0;
}
.participate-study .ui.progress.task-progress-bar .bar {
  height: 4px;
  background: #000000;
  border-radius: 0;
  min-width: 0;
}

.participate-study .ui.container.task-marks .swiper-wrapper {
  display: flex;
  align-items: stretch;
  overflow: auto;
}

.participate-study .ui.container.task-marks::-webkit-scrollbar {
  display: none;
}
.participate-study .ui.container.task-marks {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.instruction-content.content.scrolling {
  max-height: calc(80vh - 10em) !important;
  padding: 1rem 0 !important;
}

.study-body.questionnaire .swiper-wrapper {
  padding-bottom: 2.5rem !important;
}
.study-body.questionnaire .ui.checkbox input:checked ~ label:before,
.study-body.questionnaire .ui.checkbox input:checked:focus ~ label:before {
  border-color: #bdbdbd;
}
.study-body.questionnaire .ui.checkbox input:checked ~ label:after,
.study-body.questionnaire .ui.checkbox input:checked:focus ~ label:after {
  color: rgba(0, 0, 0, 0.85);
}
.study-body.questionnaire .ui.checkbox input:checked ~ label:before,
.study-body.questionnaire .ui.checkbox input:checked:focus ~ label:before {
  background-color: transparent;
}
.branding_logo {
  right: 1rem;
  bottom: 1rem;
  width: auto !important;
  max-width: 50px !important;
  position: fixed !important;
  max-height: 50px !important;
}

.study .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.study
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  box-shadow: 0 0 8px 0 rgba(246, 76, 102, 0.5) !important;
}

@media screen and (max-width: 720px) {
  body .no-navbar.in-preview {
    padding-top: 2rem;
  }
  .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .study-header,
  .study-body {
    padding: 1.5rem !important;
  }
  .study-body .participant-form.eight.wide.field,
  .study-body .ui.primary.button,
  .participate-study .ui.progress.task-progress-bar,
  .study-body.questionnaire .question-area .column.wide {
    width: 100% !important;
  }
  .study-header.participate-study {
    border-bottom: none;
  }
  .study-body.participate-study {
    padding: 0 !important;
  }
  .in-preview .study-header.questionnaire {
    padding-top: 1.5rem !important;
  }

  .study-body.questionnaire .question-area {
    flex-direction: column-reverse !important;
  }
  .study-body.questionnaire .question-area img {
    max-height: 200px !important;
  }
  .study-body.questionnaire .nav-area .column.wide {
    display: flex;
  }
  .study-body.questionnaire .nav-area .column.wide .ui.button {
    flex: 1;
  }
}

.similarity-matrix::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
