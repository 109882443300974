.report-heading small {
  font-size: 58%;
  font-weight: normal;
  vertical-align: middle;
}
.ui.time-taken .ui.label {
  width: 45px;
  color: white;
  border-radius: 2px;
  margin-right: 0.5rem;
  height: 18px;
  font-size: 12px !important;
  padding: 4px;
  text-align: center;
}
.ui.label.min {
  background-color: #7febee;
}
.ui.label.max {
  background-color: #3c6c6d;
}
.ui.label.avg {
  background-color: #2cbfca;
}
i.square.icon.completed {
  color: #2dbfca;
}
i.square.icon.abandoned {
  color: #fa3666;
}
i.square.icon.direct-success {
  color: #32d090;
}
i.square.icon.indirect-success {
  color: #adf4d7;
}
i.square.icon.direct-failure {
  color: #fa3666;
}
i.square.icon.indirect-failure {
  color: #ffa8b5;
}
i.square.icon.direct-skip {
  color: #3b88f5;
}
i.square.icon.indirect-skip {
  color: #b1cffb;
}
.ui.dropdown .menu .active.item {
  background-color: #fafafa;
}
.task-list .ui.table {
  /* flex: 1; */
  margin: auto 0.5rem;
  max-width: 6rem;
  min-width: 5rem;
  margin-top: 1px;
}
.task-list .ui.table tr td {
  height: 25px;
}
.task-list .swiper-button-prev,
.task-list .swiper-button-next {
  top: 75px;
}
.tree-nodes ul {
  list-style-type: none;
  padding-left: 1rem;
}
.tree-nodes p.node-text {
  margin: auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.tree-table,
.tree-nodes p.node-text {
  transition: all 0.25s ease-in;
}
.tree-nodes p.node-text:hover,
.tree-table:hover,
.hovered {
  background: #f5f9f7;
}
.tree-table:hover .ui.basic.label {
  color: #239fb5;
}
.most-selected {
  background: #3b88f5;
  color: white;
}
.most-success {
  background: #32d090;
  color: white;
}
.most-failed {
  background: #fa3666;
  color: white;
}

.user-email {
  max-width: 12.5rem;
  word-break: break-all;
}
.no-responses {
  padding: 6rem 0;
}
.no-responses .ui.header,
.no-responses .ui.header .sub.header {
  color: #7d9596;
  margin: 1rem auto;
}
.marker-btns .ui.button {
  flex: 0;
  min-width: auto;
  background-color: #f6f6f6 !important;
  border: 1px solid #dfe3e1;
  border-radius: 2px !important;
  box-shadow: none !important;
  font-size: 20px;
}
.responses .wide.column {
  max-height: 80vh;
  overflow: hidden auto;
}
.part-response small span,
.part-response small time {
  margin: 0 0.25rem;
}
.test-reports .ui.grid:first-child > .wide.column:last-child {
  padding: 0 !important;
}
.task-tab-menu .ui[class*="vertically divided"].grid > .row:before {
  box-shadow: none !important ;
}
.actions-row {
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.action-first-column {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.reports .ui.menu {
  margin: 0 !important;
}
.task-tab-menu .ui.grid:first-child > .wide.column:first-child {
  background: #fff !important;
}
.task-tab-menu .ui.grid:first-child > .wide.column:last-child {
  border-top: none !important;
}
.task-tab-menu .ui.menu {
  box-shadow: none !important;
}
.tree-modal .rc-tree li.filter-node > .rc-tree-node-content-wrapper {
  background: #eafefe !important;
  color: #2351f6 !important;
}
.test-setup .task-tab-menu .ui.vertical.menu .active.item {
  border-left: none !important;
  border-radius: 0 !important;
}
.tree-modal .rc-tree li .rc-tree-node-content-wrapper {
  background: #fff !important;
  border: none !important;
  padding: 0 !important;
}
.btrr-5px {
  border-top-right-radius: 5px;
}
.btlr-5px {
  border-top-left-radius: 5px;
}
.bblr-5px {
  border-bottom-left-radius: 5px;
}
.bbrr-5px {
  border-bottom-right-radius: 5px;
}
.border-blue-3px {
  border: 3px solid #2351f6;
}
.gap-3px {
  gap: 3px;
}
.ant-popover.sm-popover .ant-popover-arrow-content::before {
  background-color: #2351f6 !important;
}
.ant-popover.sm-popover .ant-popover-inner {
  background-color: #2351f6;
  border-radius: 10px;
  min-width: 200px;
  max-width: 320px;
}
.inc-exc-resp .ant-btn {
  border-left: 0 !important;
  border-radius: 0 5px 5px 0 !important;
}
.inc-exc-resp .ant-select .ant-select-selector {
  border-radius: 5px 0 0 5px !important;
}
.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}
.path,
path.invalid-path {
  stroke-width: 2px;
  stroke: #eeeeee !important;
}
path.valid-stroke {
  stroke-width: 2px;
  stroke: #00dfd2 !important;
}
path.wrong-path {
  stroke-width: 2px;
  stroke: #ff8686 !important;
}
