:root {
  --fw-500: 500;
  --fw-bold: bold;
  --blue: #3976ff;
  --dark: #424242;
  --white: #ffffff;
  --danger: #ff4d4f;
  --danger1: #e30406;
  --primary-color: #2cbfca;
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --secondary-color: #9b9b9b;

  --dark-hover: #212121;
  --primary-color-hover: #51d2d6;
  --success-color-hover: #388e3c;
  --warning-color-hover: #ef6c00;

  --montserrat: "Montserrat", sans-serif;
}

.flex-item {
  display: flex;
  align-items: center;
}

/* Typography */
a.ant-typography,
.ant-typography a {
  color: var(--primary-color);
}
a.ant-typography:hover,
.ant-typography a:hover {
  color: var(--primary-color-hover);
}

/* Sidebar */
.sidebar .ant-menu-vertical > .ant-menu-item {
  border-radius: 0 !important;
  color: var(--secondary-color);
  padding-left: 0.5rem !important;
  border-left: 5px solid transparent;
  font-family: var(--montserrat) !important;
}
.sidebar .ant-menu-vertical > .ant-menu-item.logout-btn {
  color: var(--dark);
}
.sidebar .ant-menu-vertical > .ant-menu-item .ant-menu-title-content {
  font-weight: var(--fw-500);
  font-family: var(--montserrat) !important;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: var(--primary-color);
  background: azure !important;
  border-left: 5px solid var(--primary-color);
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  color: var(--primary-color);
  background: rgba(0, 0, 0, 0.05);
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover .ant-menu-title-content,
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item .ant-menu-title-content:hover {
  color: var(--primary-color);
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content {
  font-weight: var(--fw-bold);
  color: var(--primary-color) !important;
}

/* Table */
.ant-table table {
  border-radius: 0;
  font-family: "Roboto", sans-serif !important;
}
.ant-table-wrapper .ant-table-sticky-holder {
  border-bottom: 1px solid #eeeeee;
}
.ant-table .ant-table-header table {
  border-bottom: 0 !important;
}
.ant-table .ant-table-body table {
  border-top: 0 !important;
}
.ant-table-thead > tr > th {
  font-size: 12px;
  color: #759496 !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 8px 10px !important;
}
.ant-table-thead > tr > th .ant-table-column-title {
  font-size: 12px;
  color: #759496;
}
.ant-table-tbody > tr > td {
  color: #383838;
  border: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ant-table table .ant-table-row:hover td {
  background: #f3f9fa !important;
}
.ant-table-footer {
  color: var(--white) !important;
  background: var(--blue) !important;
  padding: 0.75rem 1.25rem !important;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 0;
}
.ant-table-column-sorters {
  gap: 0.5rem;
  justify-content: unset !important;
}
.ant-table-column-title {
  flex: none !important;
}
.full-width .ant-table-column-title {
  width: 98%;
}

/* Button */
.ant-btn {
  font-weight: var(--fw-500);
}
.ant-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  color: var(--white) !important;
  border: 1px solid transparent;
  background-color: var(--primary-color);
}

.ant-btn.primary {
  min-height: 36px !important;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white) !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn.primary:hover {
  background-color: var(--primary-color-hover);
  border: 1px solid var(--primary-color-hover);
}
.ant-btn.warning,
.ant-btn-warning {
  color: var(--white);
  border-color: var(--warning-color);
  background: var(--warning-color);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn.warning:hover,
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  background-color: var(--warning-color-hover);
  border: 1px solid var(--warning-color-hover);
}
.ant-btn.success,
.ant-btn-success {
  color: var(--white);
  border-color: var(--success-color);
  background: var(--success-color);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn.success:hover,
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: var(--white) !important;
  background-color: var(--success-color-hover) !important;
  border: 1px solid var(--success-color-hover) !important;
}

.ant-btn.dark,
.ant-btn-dark {
  color: var(--white);
  border-color: var(--dark);
  background: var(--dark);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn.dark:hover,
.ant-btn-dark:hover {
  background-color: var(--dark-hover);
  border: 1px solid var(--dark-hover);
}

.ant-btn-blue {
  color: var(--white);
  background: var(--blue);
  border-color: var(--blue);
}
.ant-btn-blue:hover,
.ant-btn-blue:focus {
  color: var(--white);
  background: #0c53f1;
  border-color: #0c53f1;
}

.ant-btn-blue[disabled],
.ant-btn-blue[disabled]:hover,
.ant-btn-blue[disabled]:focus,
.ant-btn-blue[disabled]:active {
  opacity: 0.5;
  color: var(--white);
  pointer-events: none;
  background: var(--blue);
  border-color: var(--blue);
}

/* DatePicker */
.datepicker .ant-picker-input {
  flex-direction: row-reverse;
}
.datepicker .ant-picker-input > input {
  box-shadow: none !important;
  border: 0 !important;
  padding: 0.5rem 1rem !important;
  font-family: "Roboto", sans-serif !important;
  background: inherit !important;
  cursor: pointer;
}

/* Popover */
.ant-popover {
  max-width: 292px;
}
.ant-popover.primary-arrow .ant-popover-arrow-content {
  --antd-arrow-background-color: var(--primary-color);
}
.ant-popover.hide-arrow .ant-popover-arrow-content {
  display: none;
}

/* Badge */
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  display: flex;
  align-items: center;
}

/* Tooltip */
.ant-tooltip-inner {
  padding: 6px 10px;
}
.ant-form-item .ant-form-item-label > label .ant-form-item-tooltip {
  cursor: pointer;
  color: #01292c;
}

/* Carousel */
.slider-body .ant-carousel {
  width: 90%;
  margin: auto;
}
.slider-body > .anticon {
  position: absolute;
  top: calc(50% - 7px);
  z-index: 99999;
  cursor: pointer;
}
.slider-body .anticon.anticon-right {
  right: 0.5rem;
}
.slider-body .anticon.anticon-left {
  left: 0.5rem;
}
.slider-footer span {
  height: 10px;
  width: 10px;
  margin: auto 0.25rem;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
}

/* notification */
/* .ant-notification-notice {
  color: var(--white) !important;
  border-radius: 4px !important;
  padding: 0.5rem 1.85rem 0.75rem 1rem !important;
} */
.ant-notification-notice.error {
  background-color: var(--danger) !important;
}
.ant-notification-notice.success {
  background-color: var(--success-color) !important;
}
.ant-notification-notice-close {
  top: 0.75rem;
  right: 0.75rem;
}
.ant-page-header-heading {
  align-items: center;
}

/* auth screens */
.auth-container .ant-input,
.auth-container .ant-input-affix-wrapper {
  font-size: 15px;
  background: #e6efff;
  border: 2px solid #e6efff;
  border-radius: 10px;
  padding: 1rem;
}
.auth-container .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover .ant-input {
  background-color: var(--white);
}
.auth-container .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.auth-container .ant-form-item-control-input-content > .ant-input:not(.ant-input-disabled):hover,
.auth-container .ant-form-item-control-input-content > .ant-input:not(.ant-input-disabled):focus {
  background: var(--white);
  border-color: #7ba9f3;
  border-right-width: 2px !important;
}
.auth-container .ant-form-item-explain.ant-form-item-explain-error {
  color: var(--danger);
  display: none;
}

/* ant modal */
.ant-modal .ant-modal-content {
  border-radius: 10px;
}
.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}
.ant-modal .ant-modal-footer {
  border-radius: 0 0 10px 10px;
}

/* ant dropdown menu */
.ant-dropdown-menu {
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: azure;
}

/* left menu select */
.ant-tabs.ant-tabs-left.left-menu > .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  color: var(--secondary-color);
}
.ant-tabs.ant-tabs-left.left-menu > .ant-tabs-nav .ant-tabs-tab:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-tabs.ant-tabs-left.left-menu > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 500;
  background: #f0ffff;
  width: calc(100% - 1px);
}
.ant-tabs.ant-tabs-left.left-menu > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
  width: 5px;
}
.ant-tabs-left > .ant-tabs-content-holder {
  height: 100%;
  background: #ffffff;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}
.ant-tabs-left .ant-tabs-content {
  height: 100%;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  height: 100%;
  padding: 1.25rem;
  overflow-y: auto;
}

/* other styles */
.no-padding .ant-popover-inner-content {
  padding: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  opacity: 0.45;
}

.border-0.ant-tooltip-disabled-compatible-wrapper .ant-btn[disabled] {
  border: 0 !important;
}

/* ant popconfirm */
.ant-popover.ant-popconfirm .ant-popover-inner {
  border-radius: 4px;
}
.ant-popover.ant-popconfirm .ant-popover-message {
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 0 !important;
}
.ant-popover.ant-popconfirm .ant-popover-message > .anticon {
  font-size: 42px;
  position: static;
}
.ant-popover.ant-popconfirm .ant-popover-message > .ant-popover-message-title {
  padding: 0 !important;
}
.ant-popover.ant-popconfirm .ant-popover-buttons button.ant-btn {
  height: auto;
  padding: 4px 12px;
  border-radius: 4px;
}
.ant-popover.ant-popconfirm .ant-popover-buttons {
  text-align: center;
}
.ant-popover.ant-popconfirm .ant-popover-buttons button.ant-btn-primary {
  color: var(--white);
  border: 1px solid var(--danger);
  background-color: var(--danger);
}

.ant-popover.ant-popconfirm .ant-popover-buttons button.ant-btn-primary:hover {
  border: 1px solid var(--danger1);
  background-color: var(--danger1);
}

/* Top Navbar */
.top-navbar {
  top: 0;
  border: 0;
  height: 50px;
  z-index: 999;
  border-radius: 0;
  position: sticky;
  margin: 0 0 1px 0;
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(152, 152, 152, 0.5);
}

/* Form */
.ant-form-vertical .ant-form-item-label > label {
  font-weight: 500;
}
.form-inline-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-inline-item .ant-row.ant-form-item-row {
  flex-direction: row;
}
.form-inline-item.row-reverse .ant-row.ant-form-item-row {
  gap: 0.5rem;
  flex-direction: row-reverse;
}
.form-inline-item .ant-row.ant-form-item-row .ant-col:first-child {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.form-inline-item .ant-row.ant-form-item-row .ant-form-item-control {
  width: auto !important;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
a:hover {
  color: var(--primary-color-hover);
}

/* ant tree */
.ant-tree .ant-tree-treenode {
  align-items: center;
}
.ant-tree .ant-tree-switcher {
  align-self: auto;
}
.ant-tree .ant-tree-draggable-icon {
  display: none !important;
}
.ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 1rem;
}
.ant-tree .ant-tree-node-content-wrapper {
  padding: 5px 12px !important;
  border: 1px solid #e0e0e0 !important;
}
.ant-tree .ant-typography-edit-content .ant-input {
  border: 0 !important;
  padding: 0 10px !important;
  height: auto !important;
  background: transparent;
  resize: none !important;
  box-shadow: none !important;
  min-height: auto !important;
}
.ant-tree .ant-typography-edit-content .ant-typography-edit-content-confirm {
  top: 8px;
}
.ant-tree .ant-tree-treenode.editing .ant-tree-node-content-wrapper {
  border: 1px solid var(--primary-color) !important;
  border-left: 5px solid var(--primary-color) !important;
}
.ant-tree.tree-creation {
  height: calc(100% - 40px - 1rem);
}
.ant-tree.tree-creation .ant-tree-list {
  height: 100%;
  overflow: auto;
  padding-right: 5px !important;
}
.ant-tree .ant-tree-checkbox {
  margin: auto 10px auto auto !important;
}

svg.ant-dropdown-menu-item-icon {
  margin: auto;
}

/* Test Study */
.ant-tree.test-study .ant-tree-node-content-wrapper:hover .marker-icon {
  display: inline-block !important;
}

/* Ant Form */
form#testcreation .ant-input-disabled,
form#testcreation .ant-input[disabled],
form#testcreation .ant-input-number-disabled,
form#testcreation .ant-input-number[disabled],
form#testcreation .ant-picker.ant-picker-disabled,
form#testcreation .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.025);
}

form#testcreation .ant-input-affix-wrapper.sortable-card-input .ant-input {
  color: #095b61;
  font-weight: 600;
  padding-left: 0.5rem;
}
form#testcreation .ant-input-affix-wrapper.sortable-card-input:hover .visibility-none {
  visibility: visible;
}

.cat-card-name-dropdown .ant-select .ant-select-selection-item {
  line-height: 2 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.cat-card-name-dropdown .ant-select .ant-select-selection-item .anticon.anticon-check-circle {
  display: none;
}

/* Ant Spin */
.ant-spin-nested-loading .ant-spin-container {
  height: inherit;
}
